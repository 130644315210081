import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaFacebook } from 'react-icons/fa';
import './Contactos.css';

function Contacts() {
  const [enviado, setEnviado] = useState(false);

  function sendEmail(e) {
    e.preventDefault();
    const user = `${process.env.REACT_APP_EMAILJS_USER}`;
    emailjs.sendForm('gmail', 'template1', e.target, user)
      .then((res) => {
        if (res.text === 'OK') {
          setEnviado(true);
        }
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset();
  }

  return (
    <div className='contacts darkBg'>
      <div className='contacts__container'>
        <div className='contacts__row'>
          <div className='contacts__column'>
            <div className='contacts__textWrapper'>
              <h1 className='contacts__heading contacts__contactTitle'>
                Contactos
              </h1>
              <p
                className='contacts__text contacts__email'
              >
                aluminiossilveiros@sapo.pt
              </p>
              <div
                className='contacts__text'
              >
                <p
                >
                  <span>
                    (+351)&nbsp;
                  </span>
                  <span>
                    252 967 183
                  </span>
                </p>
                <p
                  className='contacts__textInfo'
                >
                  (Custo de uma chamada para a rede fixa de acordo com o seu tarifário.)
                </p>
              </div>
              <div
                className='contacts__text'
              >
                <p
                >
                  <span>
                    (António Gomes)&nbsp;
                  </span>
                  <span>
                    919 585 611
                  </span>
                </p>
                <p
                  className='contacts__textInfo'
                >
                  (Custo de uma chamada para a rede móvel de acordo com o seu tarifário.)
                </p>
              </div>
              <div
                className='contacts__text'
              >
                <p
                >
                  <span>
                    (José Ferreira)&nbsp;
                  </span>
                  <span>
                    919 377 010
                  </span>
                </p>
                <p
                  className='contacts__textInfo'
                >
                  (Custo de uma chamada para a rede móvel de acordo com o seu tarifário.)
                </p>
              </div>
              <a className='contacts__socialIconLink' href='https://www.facebook.com/serralhariadesilveiros'><FaFacebook /></a>
            </div>
          </div>

          <div className='contacts__column'>
            <div className='contacts__textWrapper'>
              <h1 className='contacts__heading contacts__timing'>
                Horário
              </h1>
              <p
                className='contacts__text'
              >
                segunda a sexta:
              </p>
              <p
                className='contacts__text'
              >
                8h00 - 12h00
              </p>
              <p
                className='contacts__text'
              >
                13h00 - 20h00
              </p>
              <p
                className='contacts__text'
              >
                sábado e domingo: <span>fechado</span>
              </p>
              <p
                className='contacts__text'
              >
                feriados: <span>fechado</span>
              </p>
            </div>
          </div>
        </div>

        <div className='contacts__row'>

          <form className='contacts__form' onSubmit={sendEmail}>
            <h1 className='contacts__contactenos red'>CONTACTE-NOS</h1>
            <div className='contacts__formInputText'>
              <div className='contacts__formInput'>
                <input type='text' name='name' id='name' placeholder='Nome:' required />
                <input type='text' name='phoneEmail' id='phone' placeholder='Telemóvel / Email:' required />
                <input type='text' name='timeToContact' id='timeToContact' placeholder='Quando prefere ser contactado? (dia/hora):' />
                <input type='text' name='subject' id='subject' placeholder='Assunto:' required />
              </div>
              <div className='contacts__formText'>
                <textarea name='message' id='message' cols='30' rows='15' placeholder='A sua mensagem:' required ></textarea>
              </div>
            </div>
            <div className='contacts__formButton'>
              <input type='submit' value='ENVIAR' />
            </div>
          </form>

          <div className='contacts__contactFormSent'>
            <h3>{enviado ? 'Pedido de contacto enviado' : ''}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
