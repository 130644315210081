import React from 'react';
import './Visite.css';

function Map() {

  return (
    <div className='map' id='visite'>
      <iframe
        title='map'
        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8455.398827435794!2d-8.572475126514421!3d41.473169783140754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7d67a58fd215cd58!2sSerralharia%20de%20Silveiros%2C%20Lda!5e0!3m2!1sen!2sus!4v1601306428027!5m2!1sen!2sus&language=pt'
        aria-hidden='false'
        style={{
          border: 0,
          width: '100%',
          height: '100%',
          maxWidth: '1300px',
          display: 'flex',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
      </iframe>
    </div >
  )
}

export default Map
