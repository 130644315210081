import React from 'react';
import './Inicio.css';
import img1 from './images/img-1_t.png';

function Inicio() {
  return (
    <div className='inicio darkBg'>
      <div className='inicio__container'>
        <div className='inicio__row' style={{ display: 'flex' }}>
          <div className='inicio__column'>
            <div className='inicio__textWrapper'>
              <div className='inicio__topLine'>
                Mais de 20 anos de experiência
              </div>
              <h1 className='inicio__heading'>
                Serralharia de Silveiros LTD
              </h1>
              <p className='inicio__text'>
                Caixilharias em alumínio
              </p>
            </div>
          </div>
          <div className='inicio__column'>
            <div className='inicio__imgWrapper'>
              <img src={img1} alt="casa" className='inicio__img' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inicio
