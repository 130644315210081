import React from 'react';
import './Home.css';
import Contactos from './Contactos';
import Visite from './Visite';
import Inicio from './Inicio';
import Empresa from './Empresa';

function Home() {
  return (
    <div className='home'>
      <>
        <section id='inicio'>
          <Inicio />
        </section>
        <section id='empresa'>
          <Empresa />
        </section>
        <section id='contactos'>
          <Contactos />
        </section>
        <section id='visite'>
          <Visite />
        </section>
      </>
    </div>
  )
}

export default Home
