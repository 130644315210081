import React from 'react';
import './Empresa.css';
import album from './images/album_t.png';

function Empresa() {
  return (
    <div className='empresa'>
      <div className='empresa__container'>
        <div className='empresa__row'>
          <div className='empresa__column'>
            <div className='empresa__textWrapper'>
              <div className='empresa__topLine'>
                profissionalismo
              </div>
              <h1 className='empresa__heading dark'>
                SOBRE NÓS
              </h1>
              <p className='empresa__text dark'>
                Especializados na produção e montagem de caixilharias de alumínio. Desenvolvemos soluções mais adequadas à necessidade de cada cliente. Com profissionalismo elevado, aliado a parceiros de topo no mercado, dispomos de um produto de excelência com preços acessíveis.
              </p>
              <div className='empresa__topLine'>
                Qualidade e Excelência
              </div>
              <h1 className='empresa__heading dark'>
                Serviços
              </h1>
              <p className='empresa__text dark'>
                Desenvolvemos e instalamos todo o tipo de janelas, portas, portadas, portões de rua, gradeamentos, marquises, pérgolas, coberturas, etc.
              </p>
            </div>
          </div>

          <div className='empresa__column'>
            <div className='empresa__imgWrapper'>
              <img src={album} alt="album" className='empresa__img' />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Empresa
