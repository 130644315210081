import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Footer from './Footer';
import Home from './Home';
import Navbar from './Navbar';

function App() {
  return (
    <div className="app">
      <Router>
          <Navbar />

          <Home />

          <Footer />
      </Router>
    </div>
  );
}

export default App;
