import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
// import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from './images/logo2.png'
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <nav className='navbar'>
      <div className='navbar__container container'>
        <Link
          smooth
          to='#inicio'
          className='navbar__logo'
          onClick={closeMobileMenu}
        >
          <img src={logo} alt='logo' className='navbar__logoImg' />
        </Link>

        <div className='navbar__menuIcon' onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>

        <ul className={click ? 'navbar__menu active' : 'navbar__menu'}>
          <li className='navbar__item'>
            <Link
              smooth
              to='#inicio'
              className='navbar__links'
              onClick={closeMobileMenu}
            >
              Início
              </Link>
          </li>

          <li className='navbar__item'>
            <Link
              smooth
              to='#empresa'
              className='navbar__links'
              onClick={closeMobileMenu}
            >
              Empresa
              </Link>
          </li>

          <li className='navbar__item'>
            <Link
              smooth
              to='#contactos'
              className='navbar__links'
              onClick={closeMobileMenu}
            >
              Contactos
              </Link>
          </li>

          <li className='navbar__item'>
            <Link
              smooth
              to='#visite'
              className='navbar__links'
              onClick={closeMobileMenu}
            >
              Visite-nos
              </Link>
          </li>

        </ul>
      </div>
    </nav>
  )
}

export default Navbar
