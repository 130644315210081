import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer__rights'>
        <small>SERRALHARIA DE SILVEIROS, LTD © {new Date().getFullYear()}</small>
        <br></br>
        <small>Desenvolvido por:
          <Link
            className='footer__socialIconLink'
            to='/'
            target='_blank'
          >
            HANF
          </Link>
        </small>
      </div>
    </div >
  )
}

export default Footer
